import React, { useState } from 'react';

import { Alert, Box, Link, Typography } from 'components/AORedesign';
import { handleFileDownload } from 'components/CommonUtilityServices/DownloadFileService';
import { handlePdfDownload } from 'components/CommonUtilityServices/DownloadPdfService';
import LinksContainer from './LinksContainer';

const PDF_ICON = 'pdf';
const E_MAIL_ICON = 'mail';
const NEW_WINDOW_ICON = 'openInNew';

const ClientFormRequests = () => {
  const [isDownloadingPdfErrorVisible, setIsDownloadingPdfErrorVisible] = useState(false);
  const [downloadingPdfError, setDownloadingPdfError] = useState('');

  const handleClickDownloadFile = (event) => {
    setIsDownloadingPdfErrorVisible(false);
    const pdfUrl = event.currentTarget.getAttribute('data-pdf');
    if (pdfUrl) {
      handlePdfDownload('GET', null, pdfUrl, () => {}, setDownloadingPdfError, setIsDownloadingPdfErrorVisible, 'newWindow');
    } else {
      const _fileUrl = event.currentTarget.getAttribute('data-file');
      handleFileDownload(_fileUrl, () => {}, setDownloadingPdfError, setIsDownloadingPdfErrorVisible);
    }
  };

  const resetErrorVisibility = () => {
    setIsDownloadingPdfErrorVisible(false);
  };

  return (
    <Box mb={2}>
      <Box mt={1} mb={4}>
        <Typography variant="h6">Forms & Requests for Your Clients</Typography>
      </Box>
      {isDownloadingPdfErrorVisible && (
        <Box mb={2}>
          <Alert color="error" alertTitleMessage={downloadingPdfError} />
        </Box>
      )}
      <Box>
        <LinksContainer heading="FORMS">
          <Link data-pdf={'/e-forms/Policyholder%20EFT%20Authorization%20Form.pdf'} onClick={handleClickDownloadFile} endIcon={PDF_ICON}>
            Policyholder EFT Enrollment Form
          </Link>
          <Link data-pdf={'/news/Statement_of_No_Loss.pdf'} onClick={handleClickDownloadFile} endIcon={PDF_ICON}>
            Statement of No Loss Form
          </Link>
        </LinksContainer>
        <LinksContainer heading="REQUESTS">
          <Link
            onClick={resetErrorVisibility}
            href={
              'mailto:customer@msagroup.com?subject=Request Forms, Declarations or ID Cards&body=Please provide the required information to request the applicable policy forms, Declaration Page or Automobile Identification Card%0A%0APolicyholder Information%0APolicy number:%0AAccount number:%0ANamed insured:%0A%0AYour Request%0APolicy Form Request%0AForm name:%0AForm number:%0A%0ADeclaration Page Request%0APolicy term dates:%0A%0AAutomobile Identification Card Request%0AVehicle year:%0AVehicle make:%0AVehicle model:%0AVIN#:'
            }
            endIcon={E_MAIL_ICON}>
            Forms, Declaration Pages, and ID Cards Requests
          </Link>
        </LinksContainer>
        <LinksContainer heading="Renewal Transition">
          <Link
            href="https://cloud.mail.msagroup.com/renewal-transition?utm_source=agentsonly&utm_medium=web&utm_campaign=renewal_transition"
            endIcon={NEW_WINDOW_ICON}>
            Renewal Transition Information
          </Link>
          <Link href="https://brandfolder.com/s/7cjv3wsnnprqkqpkq9jcbbtm" endIcon={PDF_ICON}>
            Renewal Transition Toolkit
          </Link>
        </LinksContainer>
      </Box>
    </Box>
  );
};

export default ClientFormRequests;
